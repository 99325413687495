import { ColumnType, SectionSocialNetwork, SectionRowType } from './types'
import { Loyal, Oppositional } from './components/table/icons'

export const REGION_BY_DEFAULT = 'RU-MOW'

export const ICONS = {
  0: '/static/dashboard/vkontakte.svg',
  1: '/static/dashboard/facebook.svg',
  2: '/static/dashboard/instagram.svg',
  3: '/static/dashboard/odnoklassniki.svg',
  4: '/static/dashboard/youtube.svg',
  5: '/static/dashboard/telegram.svg',
  6: '/static/dashboard/twitter.svg',
  7: '/static/dashboard/tiktok.svg',
  8: '/static/dashboard/viber.svg',
  9: '/static/dashboard/yandexZen.svg',
  10: '/static/dashboard/rutube.svg',
}

export const SmiCategoriesOpts = [
  {
    value: 0,
    label: 'Без категории',
  },
  {
    value: 1,
    label: 'ТОП 10 СМИ региона',
  },
  {
    value: 2,
    label: 'СМИ, оказывающие влияние',
  },
]

export const BlogCategoriesOpts = [
  {
    value: 0,
    label: 'Без категории',
  },
  {
    value: 4,
    label: 'Региональные обсуждения',
  },
  {
    value: 6,
    label: 'Инциденты',
  },
  {
    value: 14,
    label: 'Политика',
  },
  {
    value: 15,
    label: 'Межнациональная и межконфессиональная среда',
  },
  {
    value: 21,
    label: 'Официальные ресурсы',
  },
  {
    value: 3,
    label: 'Здравоохранение',
  },
  {
    value: 16,
    label: 'Экология',
  },
  {
    value: 13,
    label: 'Образование',
  },
  {
    value: 17,
    label: 'ЖКХ, благоустройство и стройкомплекс',
  },
  {
    value: 18,
    label: 'Культура',
  },
  {
    value: 19,
    label: 'Дорожно-транспортная сфера',
  },
  {
    value: 20,
    label: 'Социальная защита',
  },
  {
    value: 7,
    label: 'Спорт',
  },
  {
    value: 22,
    label: 'ЕДГ',
  },
  {
    value: 23,
    label: 'Другое',
  },
]

export enum UserFlags {
  None = 0,
  CanUpdate = 1 << 0, //1
  CanDelete = 1 << 1, //2
  CanManageUsers = 1 << 2, //4
  IsSuperuser = 1 << 3, //8
  CanSeeAllRegions = 1 << 4, //16
  CanUpdatePartial = 1 << 5, //32
  CanManageThemes = 1 << 6, //64
}

export enum UserOrganization {
  None = 0,
  Analytics = 1,
  Crk = 2,
}

export const COLUMNS_TO_DESCRIPTION = {
  si: 'Сумма лайков, комментов и репостов, деленная на количество постов',
  er:
    'среднее количество лайков, комментов и репостов на один пост деленное на количество подписчиков умноженное на 100',
  viewsEr:
    'SI деленный на среднее количество просмотров поста умноженный на 100',
  subscriberCount: 'Количество подписчиков',
  publicationFrequency: 'количество постов за месяц / 30 * 7',
  averageViewsCount:
    'Для Одноклассников - 18% от количества подписчиков, так как просмотров в них нет. Для Instagram просмотры есть только у видео, таким образом берется сумма всех просмотров видео и делится на количество видео (в случае отсутствия видео на странице результатом будет 0). Для всех остальных 18 соцсетей считается как сумма просмотров, деленная на количество постов за последний месяц',
}

export const COLUMNS_SOCIAL: ColumnType[] = [
  {
    name: 'Название группы',
    code: 'groupName',
    sortable: true,
  },
  {
    name: 'Соцсети',
    code: 'socialNetworks',
    sortable: false,
  },
  {
    name: 'AR',
    code: 'subscriberCount',
    sortable: true,
  },
  {
    name: 'Частота публикаций\n(в неделю)',
    code: 'publicationFrequency',
    sortable: false,
  },
  {
    name: 'ER',
    code: 'er',
    sortable: true,
  },
  {
    name: 'Средний SI',
    code: 'si',
    sortable: false,
  },
  {
    name: 'Ср. колич. просм. поста',
    code: 'averageViewsCount',
    sortable: true,
  },
  {
    name: 'Лояльность',
    code: 'loyalty',
    sortable: false,
  },
  {
    name: 'Темы',
    code: 'topics',
    sortable: false,
  },
  {
    name: 'Владелец',
    code: 'owners',
    sortable: false,
  },
  {
    name: 'Тип задачи',
    code: 'typetask',
    sortable: false,
  },
  {
    name: 'Сотрудничество',
    code: 'partnership',
    sortable: false,
  },
  {
    name: 'Дата и время добавления',
    code: 'createDate',
    sortable: true,
  },
]

export const COLUMNS_PERSON: ColumnType[] = [
  {
    name: 'Имя и должность',
    code: 'groupName',
    sortable: true,
  },
  {
    name: 'Соцсети',
    code: 'socialNetworks',
    sortable: false,
  },
  {
    name: 'AR',
    code: 'subscriberCount',
    sortable: true,
  },
  {
    name: 'Сумма подписчиков',
    code: 'totalSubscribers',
    sortable: true,
  },
  {
    name: 'ER',
    code: 'er',
    sortable: true,
  },
  {
    name: 'Средний SI',
    code: 'si',
    sortable: false,
  },
  {
    name: 'Сумма SI',
    code: 'totalSi',
    sortable: true,
  },
  {
    name: 'Ср. колич. просм. поста',
    code: 'averageViewsCount',
    sortable: true,
  },
  {
    name: 'Частота публикаций\n(в неделю)',
    code: 'publicationFrequency',
    sortable: false,
  },
  {
    name: 'Лояльность',
    code: 'loyalty',
    sortable: false,
  },
  {
    name: 'Темы',
    code: 'topics',
    sortable: false,
  },
  {
    name: 'Тип задачи',
    code: 'typetask',
    sortable: false,
  },
  {
    name: 'Сотрудничество',
    code: 'partnership',
    sortable: false,
  },
  {
    name: 'Дата и время добавления',
    code: 'createDate',
    sortable: true,
  },
]

export const COLUMNS_MEDIA: ColumnType[] = [
  {
    name: 'Название группы',
    code: 'groupName',
    sortable: true,
  },
  {
    name: 'Соцсети',
    code: 'socialNetworks',
    sortable: false,
  },
  {
    name: 'Индекс цитирования',
    code: 'citationIndex',
    sortable: true,
  },
  {
    name: 'Лояльность',
    code: 'loyalty',
    sortable: false,
  },
  {
    name: 'Темы',
    code: 'topics',
    sortable: false,
  },
  {
    name: 'Владелец',
    code: 'owners',
    sortable: false,
  },
  {
    name: 'Тип задачи',
    code: 'typetask',
    sortable: false,
  },
  {
    name: 'Сотрудничество',
    code: 'partnership',
    sortable: false,
  },
  {
    name: 'Дата и время добавления',
    code: 'createDate',
    sortable: true,
  },
]

export const COLUMNS_CRK_SOCIAL: ColumnType[] = [
  {
    name: 'Название паблика',
    code: 'groupName',
    sortable: true,
  },
  {
    name: 'Соцсети',
    code: 'socialNetworks',
    sortable: false,
  },
  {
    name: 'Подписчики',
    code: 'subscriberCount',
    sortable: true,
  },
  {
    name: 'Сумма подписчиков',
    code: 'totalSubscribers',
    sortable: true,
  },
  {
    name: 'Ср. колич. просм. поста',
    code: 'averageViewsCount',
    sortable: true,
  },
  {
    name: 'Сумма ср. просм.',
    code: 'totalViews',
    sortable: true,
  },
  {
    name: 'ER(просм.)',
    code: 'viewsEr',
    sortable: true,
  },
  {
    name: 'Средний SI',
    code: 'si',
    sortable: true,
  },
  {
    name: 'Сумма SI',
    code: 'totalSi',
    sortable: true,
  },
  {
    name: 'Частота публикаций\n(в неделю)',
    code: 'publicationFrequency',
    sortable: false,
  },
  {
    name: 'Тональность',
    code: 'loyalty',
    sortable: false,
  },
  {
    name: 'Темы',
    code: 'topics',
    sortable: false,
  },
  {
    name: 'Админ',
    code: 'owners',
    sortable: false,
  },
  {
    name: 'Сотрудничество ЦУР',
    code: 'partnership',
    sortable: false,
  },
  {
    name: 'Сотрудничество администрация',
    code: 'crkAdministrationPartnership',
    sortable: false,
  },
  {
    name: 'Комментарий',
    code: 'comment',
    sortable: false,
  },
  {
    name: 'МО',
    code: 'district',
    sortable: true,
  },
]

export const COLUMNS_CRK_PERSON: ColumnType[] = [
  {
    name: 'Имя',
    code: 'groupName',
    sortable: true,
  },
  {
    name: 'Соцсети',
    code: 'socialNetworks',
    sortable: false,
  },
  {
    name: 'Подписчики',
    code: 'subscriberCount',
    sortable: false,
  },
  {
    name: 'Сумма подписчиков',
    code: 'totalSubscribers',
    sortable: true,
  },
  {
    name: 'Ср. колич. просм. поста',
    code: 'averageViewsCount',
    sortable: false,
  },
  {
    name: 'Сумма ср. просм.',
    code: 'totalViews',
    sortable: true,
  },
  {
    name: 'ER(просм.)',
    code: 'viewsEr',
    sortable: true,
  },
  {
    name: 'Средний SI',
    code: 'si',
    sortable: false,
  },
  {
    name: 'Сумма SI',
    code: 'totalSi',
    sortable: true,
  },
  {
    name: 'Частота публикаций\n(в неделю)',
    code: 'publicationFrequency',
    sortable: false,
  },
  {
    name: 'Тональность',
    code: 'loyalty',
    sortable: true,
  },
  {
    name: 'Темы',
    code: 'topics',
    sortable: false,
  },
  {
    name: 'Сотрудничество ЦУР',
    code: 'partnership',
    sortable: false,
  },
  {
    name: 'Сотрудничество администрация',
    code: 'crkAdministrationPartnership',
    sortable: false,
  },
]

export const COLUMNS_MESSENGERS = [
  {
    name: 'Название',
    code: 'groupName',
    sortable: true,
  },
  {
    name: 'Топ канал',
    code: 'topChannel',
    sortable: false,
  },
  {
    name: 'Уровень канала',
    code: 'channelLevel',
    sortable: true,
  },
  {
    name: 'Мессенджеры',
    code: 'socialNetworks',
    sortable: false,
  },
  {
    name: 'Подписчики',
    code: 'subscriberCount',
    sortable: false,
  },
  {
    name: 'Сумма подписчиков',
    code: 'totalSubscribers',
    sortable: true,
  },
  {
    name: 'Ср. колич. просм. поста',
    code: 'averageViewsCount',
    sortable: false,
  },
  {
    name: 'Сумма ср. просм.',
    code: 'totalViews',
    sortable: true,
  },
  {
    name: 'ER(просм.)',
    code: 'viewsEr',
    sortable: true,
  },
  {
    name: 'Средний SI',
    code: 'si',
    sortable: false,
  },
  {
    name: 'Частота публикаций\n(в неделю)',
    code: 'publicationFrequency',
    sortable: false,
  },
  {
    name: 'Тональность',
    code: 'loyalty',
    sortable: false,
  },
  {
    name: 'Темы',
    code: 'topics',
    sortable: false,
  },
  {
    name: 'Сотрудничество ЦУР',
    code: 'partnership',
    sortable: false,
  },
  {
    name: 'Сотрудничество администрация',
    code: 'crkAdministrationPartnership',
    sortable: false,
  },
  {
    name: 'Комментарий',
    code: 'comment',
    sortable: false,
  },
]

export const COLUMNS_NEW_WAVE_POLITICS = [
  {
    name: 'Место в рейтинге',
    code: 'ratingPlace',
    sortable: true,
  },
  {
    name: 'ФИО',
    code: 'groupName',
    sortable: false,
  },
  {
    name: 'Должность',
    code: 'positionPolitics',
    sortable: false,
  },
  {
    name: 'Соцсети',
    code: 'socialNetworks',
    sortable: false,
  },
  {
    name: 'Подписчики',
    code: 'subscriberCount',
    sortable: false,
  },
  {
    name: 'Сумма подписчиков',
    code: 'totalSubscribers',
    sortable: true,
  },
  {
    name: 'Ср. колич. просм. поста',
    code: 'averageViewsCount',
    sortable: false,
  },
  {
    name: 'Сумма ср. просм.',
    code: 'totalViews',
    sortable: true,
  },
  {
    name: 'Средний SI',
    code: 'si',
    sortable: false,
  },
  {
    name: 'Сумма SI',
    code: 'totalSi',
    sortable: true,
  },
  {
    name: 'Частота публикаций\n(в неделю)',
    code: 'publicationFrequency',
    sortable: false,
  },
  {
    name: 'Балл в рейтинге',
    code: 'ratingValue',
    sortable: true,
  },
  {
    name: 'Название района',
    code: 'district',
    sortable: false,
  },
]

export const COLUMNS_TOP30 = [
  {
    name: 'ФИО',
    code: 'groupName',
    sortable: false,
  },
  {
    name: 'Род деятельности',
    code: 'comment',
    sortable: false,
  },
  {
    name: 'Соцсети',
    code: 'socialNetworks',
    sortable: false,
  },
  {
    name: 'Подписчики',
    code: 'subscriberCount',
    sortable: false,
  },
  {
    name: 'Сумма подписчиков',
    code: 'totalSubscribers',
    sortable: true,
  },
  {
    name: 'Ср. колич. просм. поста',
    code: 'averageViewsCount',
    sortable: false,
  },
  {
    name: 'Сумма ср. просм.',
    code: 'totalViews',
    sortable: true,
  },
  {
    name: 'Тональность',
    code: 'loyalty',
    sortable: false,
  },
]

export const LOYALTY_OBJ = {
  0: {
    label: 'Позитив',
    icon: Loyal,
    color: '#169449',
  },
  1: {
    label: 'Нейтральный',
    icon: Oppositional,
    color: '#FFCF23',
  },
  2: {
    label: 'Негатив',
    icon: Loyal,
    color: '#E74A5A',
  },
  3: {
    label: 'Неизвестно',
    icon: Loyal,
    color: '#FFFFFF',
  },
}

export const PARTNERSHIP_OPTS = [
  {
    label: 'Возможно',
    value: 0,
  },
  {
    label: 'Отказ',
    value: 1,
  },
  {
    label: 'Неизвестно',
    value: 2,
  },
]

export const PARTNERSHIP_OPTS_CRK_ONE = [
  {
    label: 'Да',
    value: 0,
  },
  {
    label: 'Нет',
    value: 1,
  },
]

export const PARTNERSHIP_OPTS_CRK_TWO = [
  {
    label: 'Возможно',
    value: 0,
  },
  {
    label: 'Отказ',
    value: 1,
  },
  {
    label: 'Неизвестно',
    value: 2,
  },
]

export enum PartnershipType {
  Approved = 0,
  Rejected = 1,
  Possible = 2,
}

export enum MediaType {
  Leader = 1,
  Influencer = 2,
  News = 3,
  Discussion = 4,
  Blog = 5,
  PublicOrganization = 6,
  CrkSocialNetworks = 7,
  CrkBloggers = 8,
  TopPublic = 9,
  Smi = 10,
  Messengers = 11,
  NewWavePolitics = 12,
  Top30 = 13,
}

export const SOCIAL_OPTS = [
  {
    label: 'Другое',
    value: 1000,
  },
  {
    label: 'Вконтакте',
    value: 0,
  },
  {
    label: 'Facebook',
    value: 1,
  },
  {
    label: 'Instagram',
    value: 2,
  },
  {
    label: 'Одноклассники',
    value: 3,
  },
  {
    label: 'Youtube',
    value: 4,
  },
  {
    label: 'Telegram',
    value: 5,
  },
  {
    label: 'Twitter',
    value: 6,
  },
  {
    label: 'TikTok',
    value: 7,
  },
  {
    label: 'Viber',
    value: 8,
  },
  {
    label: 'ЯндексДзен',
    value: 9,
  },
  {
    label: 'Rutube',
    value: 10,
  },
  {
    label: 'СМИ',
    value: 11,
  },
]

export const CHANNEL_LEVEL_OPTS = [
  {
    label: 'Неофициальный канал',
    value: 0,
  },
  {
    label: 'Официальный канал региона',
    value: 1,
  },
  {
    label: 'Канал главы региона',
    value: 2,
  },
  {
    label: 'Канал муниципального образования',
    value: 3,
  },
  {
    label: 'Канал ведомства',
    value: 4,
  },
]

export const SOCIAL_NETWORK_INIT: SectionSocialNetwork = {
  id: 0,
  link: '',
  type: null,
  subscriberCount: 0,
  citationIndex: 0,
  publicationFrequency: 0,
  er: 0,
  viewsEr: 0,
  si: 0,
  averageViewsCount: 0,
  problem: null,
  isHidden: false,
  lastUpdateDate: '',
  createDate: '',
  updateDate: '',
}

export const TABLE_ROW_INIT: SectionRowType = {
  id: 0,
  groupName: null,
  socialNetworks: [],
  loyalty: null,
  topics: [],
  crkBloggerTopics: [],
  crkPublicTopics: [],
  owners: [],
  partnership: null,
  crkAdministrationPartnership: null,
  regionCode: REGION_BY_DEFAULT,
  sectionType: null,
  contentType: 0,
  category: 0,
  comment: null,
  district: null,
  totalSubscribers: null,
  totalViews: null,
  totalSi: null,
  topChannel: null,
  channelLevel: null,
  ratingValue: null,
  ratingPlace: null,
  isMonitoring: false,
  isSocialFieldControl: false,
}

export const COLUMNS_TO_REPORT = [
  {
    number: 1,
    title: 'sectionType',
  },
  {
    number: 2,
    title: 'category',
  },
  {
    number: 3,
    title: 'loyalty',
  },
  {
    number: 4,
    title: 'partnership',
  },
  {
    number: 5,
    title: 'topics',
  },
  {
    number: 6,
    title: 'owners',
  },
  {
    number: 7,
    title: 'district',
  },
  {
    number: 8,
    title: 'comment',
  },
  {
    number: 9,
    title: 'totalSubscribers',
  },
  {
    number: 10,
    title: 'totalViews',
  },
  {
    number: 11,
    title: 'totalSi',
  },
  {
    number: 12,
    title: 'link',
  },
  {
    number: 13,
    title: 'socialNetworks',
  },
  {
    number: 14,
    title: 'subscriberCount',
  },
  {
    number: 15,
    title: 'citationIndex',
  },
  {
    number: 16,
    title: 'er',
  },
  {
    number: 17,
    title: 'viewsEr',
  },
  {
    number: 18,
    title: 'si',
  },
  {
    number: 19,
    title: 'publicationFrequency',
  },
  {
    number: 22,
    title: 'regionName',
  },
  {
    number: 23,
    title: 'isHidden',
  },
  {
    number: 24,
    title: 'crkAdministrationPartnership',
  },
  {
    number: 25,
    title: 'topChannel',
  },
  {
    number: 26,
    title: 'levelChannel',
  },
  {
    number: 27,
    title: 'ratingValue',
  },
  {
    number: 28,
    title: 'ratingPlace',
  },
  {
    number: 29,
    title: 'averageViewsCount',
  },
  {
    number: 30,
    title: 'createDate',
  },
  {
    number: 32,
    title: 'typetask',
  },
]

export const DISTRICTS = [
  {
    name: 'СЗФО',
    fullName: 'СЗФО (Северо-Западный федеральный округ)',
    code: 'szfo',
    regions: [
      {
        name: 'Ненецкий автономный округ',
        code: 'RU-NEN',
      },
      {
        name: 'Архангельская область',
        code: 'RU-ARK',
      },
      {
        name: 'Республика Коми',
        code: 'RU-KO',
      },
      {
        name: 'Мурманская область',
        code: 'RU-MUR',
      },
      {
        name: 'Ленинградская область',
        code: 'RU-LEN',
      },
      {
        name: 'Карелия',
        code: 'RU-KR',
      },
      {
        name: 'Вологодская область',
        code: 'RU-VLG',
      },
      {
        name: 'Новгородская область',
        code: 'RU-NGR',
      },
      {
        name: 'Санкт-Петербург',
        code: 'RU-SPE',
      },
      {
        name: 'Псковская область',
        code: 'RU-PSK',
      },
      {
        name: 'Калининградская область',
        code: 'RU-KGD',
      },
    ],
  },
  {
    name: 'ЦФО',
    fullName: 'ЦФО (Центральный федеральный округ)',
    code: 'cfo',
    regions: [
      {
        name: 'Ярославская область',
        code: 'RU-YAR',
      },
      {
        name: 'Тверская область',
        code: 'RU-TVE',
      },
      {
        name: 'Москва',
        code: 'RU-MOW',
      },
      {
        name: 'Ивановская область',
        code: 'RU-IVA',
      },
      {
        name: 'Костромская область',
        code: 'RU-KOS',
      },
      {
        name: 'Смоленская область',
        code: 'RU-SMO',
      },
      {
        name: 'Калужская область',
        code: 'RU-KLU',
      },
      {
        name: 'Московская область',
        code: 'RU-MOS',
      },
      {
        name: 'Владимирская область',
        code: 'RU-VLA',
      },
      {
        name: 'Брянская область',
        code: 'RU-BRY',
      },
      {
        name: 'Орловская область',
        code: 'RU-ORL',
      },
      {
        name: 'Тульская область',
        code: 'RU-TUL',
      },
      {
        name: 'Рязанская область',
        code: 'RU-RYA',
      },
      {
        name: 'Курская область',
        code: 'RU-KRS',
      },
      {
        name: 'Липецкая область',
        code: 'RU-LIP',
      },
      {
        name: 'Тамбовская область',
        code: 'RU-TAM',
      },
      {
        name: 'Белгородская область',
        code: 'RU-BEL',
      },
      {
        name: 'Воронежская область',
        code: 'RU-VOR',
      },
    ],
  },
  {
    name: 'ПФО',
    fullName: 'ПФО (Приволжский федеральный округ)',
    code: 'pfo',
    regions: [
      {
        name: 'Марий Эл',
        code: 'RU-ME',
      },
      {
        name: 'Кировская область',
        code: 'RU-KIR',
      },
      {
        name: 'Пермский край',
        code: 'RU-PER',
      },
      {
        name: 'Нижегородская область',
        code: 'RU-NIZ',
      },
      {
        name: 'Чувашия',
        code: 'RU-CU',
      },
      {
        name: 'Татарстан',
        code: 'RU-TA',
      },
      {
        name: 'Удмуртия',
        code: 'RU-UD',
      },
      {
        name: 'Мордовия',
        code: 'RU-MO',
      },
      {
        name: 'Ульяновская область',
        code: 'RU-ULY',
      },
      {
        name: 'Самарская область',
        code: 'RU-SAM',
      },
      {
        name: 'Башкортостан',
        code: 'RU-BA',
      },
      {
        name: 'Пензенская область',
        code: 'RU-PNZ',
      },
      {
        name: 'Саратовская область',
        code: 'RU-SAR',
      },
      {
        name: 'Оренбургская область',
        code: 'RU-ORE',
      },
    ],
  },
  {
    name: 'УФО',
    fullName: 'УФО (Уральский федеральный округ)',
    code: 'ufo',
    regions: [
      {
        name: 'Ямало-Ненецкий автономный округ',
        code: 'RU-YAN',
      },
      {
        name: 'Ханты-Мансийский автономный округ — Югра',
        code: 'RU-KHM',
      },
      {
        name: 'Тюменская область',
        code: 'RU-TYU',
      },
      {
        name: 'Свердловская область',
        code: 'RU-SVE',
      },
      {
        name: 'Курганская область',
        code: 'RU-KGN',
      },
      {
        name: 'Челябинская область',
        code: 'RU-CHE',
      },
    ],
  },
  {
    name: 'СФО',
    fullName: 'СФО (Сибирский федеральный округ)',
    code: 'sfo',
    regions: [
      {
        name: 'Красноярский край',
        code: 'RU-KYA',
      },
      {
        name: 'Томская область',
        code: 'RU-TOM',
      },
      {
        name: 'Кемеровская область',
        code: 'RU-KEM',
      },
      {
        name: 'Иркутская область',
        code: 'RU-IRK',
      },
      {
        name: 'Новосибирская область',
        code: 'RU-NVS',
      },
      {
        name: 'Хакасия',
        code: 'RU-KK',
      },
      {
        name: 'Омская область',
        code: 'RU-OMS',
      },
      {
        name: 'Алтайский край',
        code: 'RU-ALT',
      },
      {
        name: 'Тыва',
        code: 'RU-TY',
      },
      {
        name: 'Республика Алтай',
        code: 'RU-AL',
      },
    ],
  },
  {
    name: 'ЮФО',
    fullName: 'ЮФО (Южный федеральный округ)',
    code: 'yufo',
    regions: [
      {
        name: 'Севастополь',
        code: 'RU-SEV',
      },
      {
        name: 'Крым',
        code: 'RU-CR',
      },
      {
        name: 'Адыгея',
        code: 'RU-AD',
      },
      {
        name: 'Краснодарский край',
        code: 'RU-KDA',
      },
      {
        name: 'Ростовская область',
        code: 'RU-ROS',
      },
      {
        name: 'Калмыкия',
        code: 'RU-KL',
      },
      {
        name: 'Астраханская область',
        code: 'RU-AST',
      },
      {
        name: 'Волгоградская область',
        code: 'RU-VGG',
      },
      {
        name: 'Сириус',
        code: 'RU-SIR',
      },
    ],
  },
  {
    name: 'СКФО',
    fullName: 'СКФО (Северо-Кавказский федеральный округ)',
    code: 'skfo',
    regions: [
      {
        name: 'Карачаево-Черкесия',
        code: 'RU-KC',
      },
      {
        name: 'Ставропольский край',
        code: 'RU-STA',
      },
      {
        name: 'Чечня',
        code: 'RU-CE',
      },
      {
        name: 'Дагестан',
        code: 'RU-DA',
      },
      {
        name: 'Кабардино-Балкария',
        code: 'RU-KB',
      },
      {
        name: 'Северная Осетия',
        code: 'RU-SE',
      },
      {
        name: 'Ингушетия',
        code: 'RU-IN',
      },
    ],
  },
  {
    name: 'ДФО',
    fullName: 'ДФО (Дальневосточный федеральный округ)',
    code: 'dfo',
    regions: [
      {
        name: 'Якутия',
        code: 'RU-SA',
      },
      {
        name: 'Магаданская область',
        code: 'RU-MAG',
      },
      {
        name: 'Чукотский автономный округ',
        code: 'RU-CHU',
      },
      {
        name: 'Камчатский край',
        code: 'RU-KAM',
      },
      {
        name: 'Амурская область',
        code: 'RU-AMU',
      },
      {
        name: 'Хабаровский край',
        code: 'RU-KHA',
      },
      {
        name: 'Бурятия',
        code: 'RU-BU',
      },
      {
        name: 'Еврейская автономная область',
        code: 'RU-YEV',
      },
      {
        name: 'Сахалинская область',
        code: 'RU-SAK',
      },
      {
        name: 'Забайкальский край',
        code: 'RU-ZAB',
      },
      {
        name: 'Приморский край',
        code: 'RU-PRI',
      },
    ],
  },
  {
    name: 'Новые регионы',
    fullName: 'НТ (Новые территории)',
    code: 'nt',
    regions: [
      {
        name: 'ЛНР',
        code: 'RU-LNR',
      },
      {
        name: 'ДНР',
        code: 'RU-DNR',
      },
      {
        name: 'Запорожская область',
        code: 'RU-ZPR',
      },
      {
        name: 'Херсонская область',
        code: 'RU-HRS',
      },
    ],
  },
  {
    name: 'Федеральный уровень',
    fullName: 'Федеральный уровень',
    code: 'RU-FED',
    regions: [],
  },
]
