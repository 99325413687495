import React from 'react'

export const Loyal = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#169449"
    />
    <path
      d="M18.8569 6.85693L9.42829 16.2855L5.14258 11.9998"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Oppositional = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0869 24C18.7143 24 24.0869 18.6274 24.0869 12C24.0869 5.37258 18.7143 0 12.0869 0C5.4595 0 0.0869141 5.37258 0.0869141 12C0.0869141 18.6274 5.4595 24 12.0869 24Z"
      fill="#E74A5A"
    />
    <path
      d="M17.23 6.85693L6.94434 17.1426M6.94434 6.85693L17.23 17.1426"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ModeratelyOppositional = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0869 24C18.7143 24 24.0869 18.6274 24.0869 12C24.0869 5.37258 18.7143 0 12.0869 0C5.4595 0 0.0869141 5.37258 0.0869141 12C0.0869141 18.6274 5.4595 24 12.0869 24Z"
      fill="#2F3034"
    />
  </svg>
)
