import { Store } from '../store/with-redux-store'
import { UserFlags } from '../features/section/constants'
import { useSelector } from 'react-redux'
import { RootState } from '../store/store'
import { UserState } from '../store/models/user'

export const isUser = (reduxStore: Store): boolean => {
  if (reduxStore) {
    const user = reduxStore.getState().user
    return !!user.fio
  }
}

export const isBlocked = (reduxStore: Store): boolean => {
  if (reduxStore) {
    const user = reduxStore.getState().user
    return user.isBlocked || user.isDeleted
  }
}

export const isNoVisibleRegions = (reduxStore: Store): boolean => {
  const user = reduxStore
    ? reduxStore.getState().user
    : useSelector<RootState, UserState>((state) => state.user)

  const organization = reduxStore
    ? reduxStore.getState().section.organization
    : useSelector<RootState, number>((state) => state.section.organization)

  if (!user.fio) {
    return false
  }

  return (
    !checkAccess(UserFlags.CanSeeAllRegions, reduxStore) &&
    !user.organizationAccess[organization]?.visibleRegions?.length
  )
}

export const isDepartment = (reduxStore: Store): boolean => {
  if (reduxStore) {
    const user = reduxStore.getState().user
    return user.flags === UserFlags.IsSuperuser ? true : user.department
  }
}

// проверка прав пользователя @access в выбранной организации
export const checkAccess = (access: UserFlags, reduxStore?: Store): boolean => {
  const user = reduxStore
    ? reduxStore.getState().user
    : useSelector<RootState, UserState>((state) => state.user)

  if (user.isSuperuser) {
    return true
  }

  const organization = reduxStore
    ? reduxStore.getState().section.organization
    : useSelector<RootState, number>((state) => state.section.organization)
  const userFlags = user.organizationAccess[organization]?.flags
  return (access & userFlags) === access
}

export const isUserOrganization = (
  organization: number,
  reduxStore?: Store,
): boolean => {
  const user = reduxStore
    ? reduxStore.getState().user
    : useSelector<RootState, UserState>((state) => state.user)

  if (!user.fio) {
    return false
  }

  return Object.keys(user.organizationAccess).includes(organization?.toString())
}
